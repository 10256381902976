import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonContent,
	IonLoading,
	IonButton,
	IonButtons,
	//IonListHeader,
	//IonLabel,
} from '@ionic/react';

import { AppContext,
				getStreams,
				//getHotStreams,
				getFavStreams,
				//getNewStreams,
				//getRecentStreams,
				//playStream,
				setStreams,
				getStreamTypeTab,
				setStreamTypeTab }
				from '../State';

import { AUTH_SERVER } from '../config';
import { img, useFetch } from '../util';
import RowStreamsSlides from '../components/RowStreamsSlides.jsx';
import Promotion from '../components/promotion/Promotion.jsx';
import './Home.css';
import StreamPlayer from '../components/StreamPlayer';
import { ISP_NAME } from '../config'

const Home = () => {
	const { state, dispatch } = useContext(AppContext);
	const { t } = useTranslation();
	const { loading, data, error } = useFetch(
		`${AUTH_SERVER}/private/iptvPackage/getStreamsById`,
		{
			method: 'get',
			headers: {
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json',
				'authorization': 'Bearer '+localStorage.getItem('token')
			},
		}
	);

	useEffect(() => {
		console.log("HOME->useEffect setStreams");
		if (data && data.copyStreams) dispatch(setStreams(data.copyStreams));
		return () => {
			// Limpiar
		};
	},[dispatch,data]); /* Only when data we get from server changes, this effect is executed */

	if (loading) return <IonLoading isOpen={loading} message={t('home.get_streams_from_server')}/>;
	if (error)
		return <IonLoading isOpen={loading} message={JSON.stringify(error, null, 2)}/>;

	//const hotStreams = getHotStreams(state);
	//const newStreams = getNewStreams(state);
	const allStreams = getStreams(state);
	const favStreams = getFavStreams(state);
	//const recentStreams = getRecentStreams(state);
	const streamTypeTab_ = getStreamTypeTab(state);
	const tvStreams = allStreams.filter((item) => item.type === 'TV');
	const cctvStreams = allStreams.filter((item) => item.type === 'CCTV');
	const moviesStreams = allStreams.filter((item) => item.type === 'MOVIES');
	const seriesStreams = allStreams.filter((item) => item.type === 'SERIES');

	let tvStreamsCategories = []

	tvStreams.map(( item ) => {
		if (!tvStreamsCategories.includes( item.category )) {
			tvStreamsCategories.push( item.category )
		}
	})

	tvStreamsCategories.sort(( a, b ) => a.localeCompare(b))

	let tvStreamsSorted = []

	tvStreamsCategories.map( ( item ) => {
		tvStreamsSorted.push({
			title: item,
			streams: tvStreams.filter( ( streamItem ) => streamItem.category === item ).sort(( a, b ) => a.name.localeCompare(b.name))
		})
	})

	const moviesStreamsComedia = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("COMEDIA"));
	const moviesStreamsDrama = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("DRAMA"));
	const moviesStreamsRomance = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("ROMANCE"));
	const moviesStreamsCrimen = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("CRIMEN"));
	const moviesStreamsHistoria = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("HISTORIA"));
	const moviesStreamsTerror = moviesStreams.filter((item) => item.categoryTags && item.categoryTags.toUpperCase().includes("TERROR"));
	const moviesStreamsAccion = moviesStreams.filter((item) => item.categoryTags && (item.categoryTags.toUpperCase().includes("ACCION") || item.categoryTags.toUpperCase().includes("ACCIÓN")) );
	const moviesStreamsFamilia = moviesStreams.filter((item) => item.categoryTags && (item.categoryTags.toUpperCase().includes("ACCION") || item.categoryTags.toUpperCase().includes("FAMILIA")) );

	let today = new Date();
	let twoWeeksBefore = today.setDate(today.getDate() - 14); //EPOC milliseconds date
	const moviesStreamsRecent = moviesStreams.filter((item) => (new Date(item.createdAt).getTime()) >= twoWeeksBefore );

	/*const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};*/

	return (
		<IonPage>
		 {/* <IonMenu side="start" menuId="first" content-id="main-content" disabled>
				<IonHeader>
					<IonToolbar color="primary">
						<IonTitle>Menu - IPTV</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList>
						<IonItem>FAQ</IonItem>
						<IonItem>Menu Item</IonItem>
						<IonItem>Wifreebytes</IonItem>
						<IonItem>Facturació</IonItem>
						<IonItem>Logout</IonItem>
					</IonList>
				</IonContent>
		</IonMenu> */}
			<IonContent id="main-content">
				<IonHeader className="gradient-background">
					<IonToolbar className="display-block">
						<IonButtons style={{padding:'20px'}}>
							<img className="logo" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="40px" alt="logo" />
							<img className="logoSmall" style={{marginRight:'20px'}} src={"../assets/logo-"+ISP_NAME+".svg"} height="30px" alt="logo" />

							{ tvStreams.length > 0 &&
								<IonButton onClick={()=> dispatch(setStreamTypeTab("TV"))} className={streamTypeTab_ === 'TV'?'selected menu':'menu'}>Tv</IonButton>
							}
							{ cctvStreams.length > 0 &&
								<IonButton onClick={()=> dispatch(setStreamTypeTab("CCTV"))} className={streamTypeTab_ === 'CCTV'?'selected menu':'menu'}>Cctv</IonButton>
							}
							{ moviesStreams.length > 0 &&
								<IonButton onClick={()=> dispatch(setStreamTypeTab("MOVIES"))} className={streamTypeTab_ === 'MOVIES'?'selected menu':'menu'}>{t('home.movies')}</IonButton>
							}
								<IonButton onClick={()=> dispatch(setStreamTypeTab("SERIES"))} className={streamTypeTab_ === 'SERIES'?'selected menu':'menu'}>{t('home.series')}</IonButton>
						</IonButtons>

						{streamTypeTab_ === 'MOVIES' &&
							<Promotion type="MOVIES" />
						}
					</IonToolbar>
				</IonHeader>
				<div className="background-ago">
					<div className="gradient-background">
						<div className="light-background fifpadding-bot">
							<div className="content-spacing">

								{(favStreams.length > 0 && favStreams.filter( i => i.type === streamTypeTab_).length > 0) &&
									<RowStreamsSlides key={"rss_fav_"+streamTypeTab_} id={"rss_fav_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('home.favourites')+streamTypeTab_} streams={favStreams} />
								}

								{/* (recentStreams.length > 0 && recentStreams.filter( i => i.type === streamTypeTab_).length > 0) &&
									<RowStreamsSlides key={"rss_recent_"+streamTypeTab_} id={"rss_recent_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={"Recent "+streamTypeTab_} streams={recentStreams} />
								*/}

								{ streamTypeTab_ === 'TV' && tvStreamsSorted.map(( stream ) => (
									<RowStreamsSlides key={ `rss_${ stream.title }_${ streamTypeTab_ }` } id={ `rss_${ stream.title }_${ streamTypeTab_ }` } streamTypeTab={ streamTypeTab_ } rowTitle={ stream.title } streams={ stream.streams } />
								)) }

								{streamTypeTab_ === 'MOVIES' && moviesStreamsRecent.length >0 &&
									<RowStreamsSlides key={"rss_movie_recent_"+streamTypeTab_} id={"rss_movie_recent_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.recent')} streams={moviesStreamsRecent} />
								}

								{streamTypeTab_ === 'MOVIES' && moviesStreamsComedia.length >0 &&
									<RowStreamsSlides key={"rss_movie_comedia_"+streamTypeTab_} id={"rss_movie_comedia_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.comedia')} streams={moviesStreamsComedia} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsDrama.length >0 &&
									<RowStreamsSlides key={"rss_movie_drama_"+streamTypeTab_} id={"rss_movie_drama_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.drama')} streams={moviesStreamsDrama} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsRomance.length >0 &&
									<RowStreamsSlides key={"rss_movie_romance_"+streamTypeTab_} id={"rss_movie_romance_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.romance')} streams={moviesStreamsRomance} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsFamilia.length >0 &&
									<RowStreamsSlides key={"rss_movie_familia_"+streamTypeTab_} id={"rss_movie_familia_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.family')} streams={moviesStreamsFamilia} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsAccion.length >0 &&
									<RowStreamsSlides key={"rss_movie_accion_"+streamTypeTab_} id={"rss_movie_accion_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.accion')} streams={moviesStreamsAccion} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsCrimen.length >0 &&
									<RowStreamsSlides key={"rss_movie_crimen_"+streamTypeTab_} id={"rss_movie_crimen_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.crimen')} streams={moviesStreamsCrimen} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsHistoria.length >0 &&
									<RowStreamsSlides key={"rss_movie_historia_"+streamTypeTab_} id={"rss_movie_historia_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.historia')} streams={moviesStreamsHistoria} />
								}
								{streamTypeTab_ === 'MOVIES' && moviesStreamsTerror.length >0 &&
									<RowStreamsSlides key={"rss_movie_terror_"+streamTypeTab_} id={"rss_movie_terror_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('movie.terror')} streams={moviesStreamsTerror} />
								}
								{ streamTypeTab_ === 'TV' &&
									<RowStreamsSlides key={"rss_all_"+streamTypeTab_} id={"rss_all_"+streamTypeTab_} streamTypeTab={streamTypeTab_} rowTitle={t('home.all')} streams={allStreams} />
								}
							</div>
						</div>
					</div>
				</div>
			</IonContent>
			{ state.player.streams.length > 0 && <StreamPlayer /> }
		</IonPage>
	);
};

export default Home;
