import React, { useCallback, useContext } from 'react';

import {
	IonModal, IonHeader, IonToolbar, IonTitle, IonContent,
	//IonRange,
	IonButtons,
	IonButton,
	IonIcon,
	IonFab,
	IonItem,
	IonLabel,
	IonFabButton,
	IonThumbnail
} from '@ionic/react';

import {
	arrowDown, heart, heartOutline,
	/*playSkipBack,
	play,
	pause,
	playSkipForward,
	removeCircleOutline*/
} from 'ionicons/icons';

import {
	AppContext, isPlayerOpen, closePlayer, getPlaying, getCurrentStream, isFavStream,
favStream,
getCurrentPlaylist,
playStream,
setPlayList
/*pauseStream,
seekStream,
nextStream,
prevStream */
}
from '../State';

import { Link } from 'react-router-dom';
import urls from '../urls';

import './StreamPlayer.css';
import VideojsPlayer from './VideojsPlayer';
import Epg from './Epg';
import { img } from '../util';

const StreamPlayer = ({ stream2, closed }) => {
	const { state, dispatch } = useContext(AppContext);
	const playing = getPlaying(state);

	const handleClose = useCallback(() => {
		dispatch(closePlayer());
	}, [dispatch]);

	const doPlay = useCallback(stream => {
    dispatch(playStream(stream));
    dispatch(setPlayList([stream]));
  }, [dispatch]);

	if (!playing) {
		return null;
	}
	const open = isPlayerOpen(state);
	const stream = getCurrentStream(state);
	const currentPlaylist = getCurrentPlaylist(state);

	// const isFav = isFavStream(state, stream);

	if (stream)

	return (
		<IonModal
			isOpen={open}
			onDidDismiss={handleClose}
			cssClass="fullscreen stream-player">
			<IonHeader>
				<IonToolbar className='ago-background'>
					<IonButtons slot="start">
						<IonButton fill="clear" onClick={() => dispatch(closePlayer())}>
							<IonIcon icon={arrowDown} />
						</IonButton>
					</IonButtons>
					{(stream.type === 'TV' || stream.type === 'CCTV') &&
						<div className='background-ch-thumb' slot='end'>
							<div className='background-ch-thumb-inner'>
								<img src={img(stream.img)+'.svg'} className="channel-thumbnail" />
							</div>
						</div>
					}
					<IonTitle>
						<strong>{stream.title}</strong>
						{stream.type === 'TV' &&
							<>
								<span> - {stream.type === 'TV'?stream.description:''}</span>
							</>
						}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<VideojsPlayer slot="start" key={stream.url}
				{...{
				autoplay:true,
				fluid:true,
				controls:true,
				controlBar: {
					'volumePanel': false,
					'liveDisplay': false
				},
				plugins:{
				},
				html5: {
					hlsjsConfig: {
						debug: true
					}
				},
				sources:[{
					src:stream.url
					}
				],
				}} playlist={currentPlaylist} />

			<IonContent>
				{/*<IonFab  horizontal="end" vertical="center" slot="fixed">
					<IonFabButton color={isFav?"primary":"light"} secondary><IonIcon onClick={() => dispatch(favStream(stream))} icon={isFav ? heart : heartOutline} /></IonFabButton>
						{ (stream.type ==='TV' || stream.type === 'CCTV') /*&&
						<Link to={urls.APP_DVR+"/"+(stream.type==='DVR'?stream.parent:stream.name)}  onClick={() => dispatch(closePlayer())} ><IonFabButton secondary>DVR</IonFabButton></Link>
				</IonFab>  */}

				{ ( !(stream.epgId === null || stream.epgId === "") && ( stream.type === 'TV' || stream.type === 'DVR')) &&
					<Epg stream={stream} />
				}
				{ (stream.type === 'MOVIES' || stream.type === 'SERIES') &&
					<div style={{padding:'25px'}}>
						<ion-text color="medium">
							<h3><b>Categorias:</b> {stream.categoryTags}</h3>
							<p className="description"><b>Descripción:</b> {stream.description}</p><br></br>
							<img width="150px" src={stream.posterURL} alt={stream.title} />
						</ion-text>
					</div>
				}
				{ !stream.epgId &&
					<IonContent className="related-channels">
						<div className="light-background height-100">
							<div className="item-list">
								{currentPlaylist.map(stream => (
									<IonItem key={stream.title} onClick={() => doPlay(stream)} button>
										<IonThumbnail slot="start" className="channel-badge">
											{stream.type === 'MOVIES' &&
											<img src={stream.posterURL} alt={stream.title}/>
											}
											{stream.type !== 'MOVIES' &&
											<img src={img(stream.img)+'.svg'} alt={stream.title}/>
											}
										</IonThumbnail>
										<IonLabel className="channel-info">
											<h2><b>{stream.title}</b></h2>
											<p>{stream.description}</p>
										</IonLabel>
									</IonItem>
								))}
							</div>
						</div>
					</IonContent>
				}
			</IonContent>
		</IonModal>
	)
};



export default StreamPlayer;