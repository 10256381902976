//const AUTH_SERVER = 'https://i.wifibytes.com';
//const DVR_SERVER = 'https://iptv.wifibytes.com';
const AUTH_SERVER = 'https://middleware.agotelecom.com';
const DVR_SERVER = 'https://iptv.agotelecom.com';
const ISP_NAME = 'Ago'
const ISP_WEB = 'http://agotelecom.com'

//const AUTH_SERVER = "http://localhost:4443";


let today = new Date();

/**
 * In winter in Spain offSet should be 1 hour less (-3600s) and in summer (-7200s)
 * there is date.dst() method too (daylight saving time) to know if we are in summer time
 */
const TIMESHIFT_EPG = today.getTimezoneOffset() * 60; 


export { AUTH_SERVER, DVR_SERVER, ISP_NAME,ISP_WEB, TIMESHIFT_EPG };
